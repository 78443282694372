import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj8 = () => {
  return (
    <Layout>
      <SEO
        title="Browser Automation-Whatsapp"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>
      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-8">
          <div className="py-1-heading">
            <h1>Browser Automation-Whatsapp</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Browser%20Automation-Whatsapp"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              Now, such projects make a really strong interest in the field.
              <br />
              <br />
              Here, we are using webdriver for a browser,
              <br />
              then we access a website, <br />
              In our case, it is Whatsapp web.
              <br />
              <br />
              Then we target a particular person/friend to whom we are going to
              send a message using python.
              <br />
              <br />
              Well, in my case it's already sent...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Selenium</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Webdriver</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj8
